import React, { useEffect, useState } from "react";
import { ajax, validate, removeModalFadeIn } from '../shared/utils';
import { ViewComponentProps, withView } from "../shared/viewcomponent";
import { Formik, Form } from 'formik';
import './css/systemConfiguration.css';
import GlobalizedText from '../shared/globalization';
import * as yup from 'yup';
import { VALIDATION_REGEX } from "../shared/regex";
import { useMessage } from "../shared/message";
import { FeatureFlags } from '../shared/featureFlags';

export const SystemConfigurationComponent = withView(function (props: ViewComponentProps) {
    const [config, setConfig] = useState<any | null>(null);
    const messageService4Email = useMessage("emailUpdate");
    const [tab, setTab] = useState<Level>('forgetUserId');
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const isDisplayPayrollSchedule = props.isFeatureEnabled(FeatureFlags.ENROLLMENT_UPDATEPAYROLL);
    const isDisplayPolicyNotes = props.isFeatureEnabled(FeatureFlags.POLICY_NOTES);

    type Level = 'forgetUserId' | 'messageCenter'

    useEffect(() => {
        if (config === null) {
            updateHandeler();
        }
    });

    useEffect(() => {
        if (isUpdated) {
            updateHandeler();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    const updateHandeler = () => {
        ajax({
            url: '/api/systemConfiguration/view',
            success: (res: any) => {
                setConfig(res);
            }
        })
    };

    if (config === null) {
        return <></>
    }
    //billingHistPeriod
    let billingHistPeriodSchema = yup.object().shape({
        billingHistPeriod: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });
    //claimHistPeriod
    let claimHistoryPeriodSchema = yup.object().shape({
        claimHistPeriod: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });
    //covSumPeriod
    let covSumPeriodSchema = yup.object().shape({
        covSumPeriod: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });
    //enrollTemLimit
    let enrollTemLimitSchema = yup.object().shape({
        enrollTemLimit: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });
    //payrollSchPeriodSchema
    let payrollSchPeriodSchema = yup.object().shape({
        payrollSchPeriod: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });
    //memYearTemSchema
    let memYearTemSchema = yup.object().shape({
        memYearTem: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });
    //billPayInqPeriod
    let billPayInqPeriodSchema = yup.object().shape({
        billPayInqPeriod: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });
    //polNotesPeriod
    let polNotesPeriodSchema = yup.object().shape({
        polNotesPeriod: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });
    //userInqPeriod
    let userInqPeriodSchema = yup.object().shape({
        userInqPeriod: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });
    //userInqPeriod
    let userListDurationSchema = yup.object().shape({
        userListDuration: yup.string().trim().matches(VALIDATION_REGEX.Positive_Int, "common.msg.ValueNotPositive")
    });

	const JAVA_INTEGER_MAX = 2147483647;

    const naturalNumberSchema = (fieldName: string) => yup.object().shape({
        [fieldName]: yup.number()
						.typeError("common.msg.ValueNotPositive")
						.positive("common.msg.ValueNotPositive")
						.integer("common.msg.ValueNotPositive")
						.max(JAVA_INTEGER_MAX, "common.lbl.IntegerMax")
    });

	const largeTextSchema = (fieldName: string) => yup.object().shape({
		[fieldName]: yup.string().trim().required()
	});

    const emailUpdateSchema = yup.object().shape({
        emailProtocol: yup.string().trim().required("sysconf.msg.mail.emailProtocol.required"),
        emailHost: yup.string().trim().required("sysconf.msg.mail.emailHost.required"),
        emailTrust: yup.string().trim().required("sysconf.msg.mail.emailTrust.required"),
        emailPort: yup.string().trim().required("sysconf.msg.mail.emailPort.required"),
        emailFrom: yup.string().trim().required("sysconf.msg.mail.emailFrom.required"),
        emailPwd: yup.string().trim().required("sysconf.msg.mail.emailPwd.required"),
        emailUser: yup.string().trim().required("sysconf.msg.mail.emailUser.required"),
        emailSub: yup.string().trim().required("sysconf.msg.mail.emailSub.required"),
        emailBody: yup.string().trim().required("sysconf.msg.mail.emailBody.required"),
        emailSocketPort: yup.string().trim().required("sysconf.msg.mail.emailSocketPort.required"),
        emailAlias: yup.string().trim().required("sysconf.msg.mail.emailAlias.required")
    });

    function resetEmailForm(Props: any, formProps: any) {
        Props.resetForm(formProps);
    }
    function openEmailUpdateDialog() {
        messageService4Email.validateProps.clearMessage();
        props.showModal('#email_conf');
    }

    function handelFailed(resFail: any, errorMsg: any) {
        props.showMessage("error", errorMsg);
    }
    function handelError(error: any) {
        props.showMessage('error', error.response.data.message)
    }

    function clickTab(level: Level, formProps: any) {
        if (level === tab) {
            return;
        }
        setTab(level);
        props.resetForm(formProps);
        props.clearMessage();
    }

	function Section(props: any) {
		return <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label exportSubject" htmlFor={props.name}>
                    <GlobalizedText message={props.messageId} />
                </props.Label>
            </props.Col>
        </props.Row>;
	}
	
	function Heading(props: any) {
		return <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label" htmlFor={props.name}>
                    <GlobalizedText message={props.messageId} />
                </props.Label>
            </props.Col>
        </props.Row>;
	}
	function StandardForm(props: any) {
		return <Formik
            initialValues={{ [props.name]: config.systemConfig.configMap[props.parameterName] }}
            validate={values => {
                return validate(props.schema(props.name), values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/update',
                    params: {
						"parameterName": props.parameterName,
                        "parameterValue": values[props.name]
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "common.msg.updateSuccess");
                    }, fail: res => {
                    }
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='10' containerClassName="sysconf-inputAddon" id={props.name} name={props.name} addonMsg={props.messageId}></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>;
	}
	function AnnotatableForm(props: any) {
		return <>
		<Formik
            initialValues={{ [props.name]: config.systemConfig.configMap[props.parameterName] }}
            validate={values => {
                return validate(props.schema(props.name), values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/update',
                    params: {
						"parameterName": props.parameterName,
                        "parameterValue": values[props.name]
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "common.msg.updateSuccess");
                    }, fail: res => {
                    }
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='10' containerClassName="sysconf-inputAddon" id={props.name} name={props.name} addonMsg={props.messageId}></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
							<props.Button className="btn btn-info gwp-btn" onClick={() => props.showModal('#' + props.name + '_notesModal')}><GlobalizedText message="common.lbl.notes" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        <Formik
            enableReinitialize={true}
            initialValues={{[props.name+'_notes']: config.systemConfig.configMap[props.parameterName+'.notes']}}
            validateOnBlur={false}
            validateOnChange={true}
            validate={(values) => {
                return validate(largeTextSchema(props.name +'_notes'), values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/update',
                    params: {
						"parameterName": props.parameterName+'.notes',
                        "parameterValue": values[props.name+'_notes']
                    },
                    method: 'put',
                    success: res => {
                        setIsUpdated(true);
                        props.showMessage("success", "common.msg.updateSuccess");
						removeModalFadeIn();
                    },
                    fail: handelFailed,
                    error: handelError
                });
            }
            }
        >
            {formProps =>
                <Form>
                    <props.Modal title={'common.msg.' + props.parameterName + '.notes'} id={props.name + '_notesModal'} footer={() => {
                        return <>
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.lbl.save" /></props.Button>
                            <props.Button data-dismiss="modal" onClick={(e: any) => { props.resetForm(formProps)}}><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }} >
                        <props.Row>
                            <props.Col sm="12">
                    			<GlobalizedText message={props.parameterName + '.notes'} />
                                <props.TextareaControl style={{ maxWidth: '100%', height: '300px' }} id={props.name + "_notes"} name={props.name + "_notes"} required={true}></props.TextareaControl>
                            </props.Col>
                        </props.Row>
                    </props.Modal>
                </Form>
            }
        </Formik>
		</>	
	}
	
    return <>
        {/* Update Billing History Period start */}
        <props.Row>
            <props.Col md="12">
                <props.Label className="gwp-label" htmlFor="billingHistPeriod">
                    <GlobalizedText message="sysconf.billing.inquiry.period" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{
                billingHistPeriod: config.systemConfig['billingHistoryPeriod']
            }
            }
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(billingHistPeriodSchema, values, props);
            }}
            onSubmit={(values) => {
                //reg values.
                ajax({
                    url: '/api/systemConfiguration/updateBillingPeriod',
                    params: {
                        "billingHistoryPeriod": values.billingHistPeriod
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "sysconf.billing.inquiry.period.update.success");
                    }, fail: handelFailed,
                    error: handelError
                });
            }
            }
        >
            {formProps =>
                <Form>
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='6' containerClassName="sysconf-inputAddon" id="billingHistPeriod" name="billingHistPeriod" addonMsg="common.lbl.months"></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }

        </Formik>
        {/* Update Billing History Period end */}

        <props.HR></props.HR>
        {/* Update Claim History Period start*/}
        <props.Row>
            <props.Col md="12">
                <props.Label className="gwp-label" htmlFor="claimHistPeriod">
                    <GlobalizedText message="common.lbl.clm.hist.per" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{ claimHistPeriod: config.systemConfig['claimHistoryPeriod'] }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(claimHistoryPeriodSchema, values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateClaimHistoryPeriod',
                    params: {
                        "claimHistoryPeriod": values.claimHistPeriod
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "common.msg.CMPeriodUpdateSuccess");
                    },
                    fail: handelFailed,
                    error: handelError
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='6' containerClassName="sysconf-inputAddon" id="claimHistPeriod" name="claimHistPeriod" addonMsg="common.lbl.months" ></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Update Claim History Period end*/}

        <props.HR></props.HR>

        {/* Update Coverage Summary History Period start */}
        <props.Row>
            <props.Col md="12" sm="12" xs="12">
                <props.Label className="gwp-label" htmlFor="covSumPeriod">
                    <GlobalizedText message="sysconf.coverage.summary.period" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{ covSumPeriod: config.systemConfig['summaryHistoryPeriod'] }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(covSumPeriodSchema, values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateCoverageSummaryPeriod',
                    params: {
                        "summaryHistoryPeriod": values.covSumPeriod
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "sysconf.coverage.summary.period.update.success");
                    },
                    fail: handelFailed,
                    error: handelError
                });
            }

            }

        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='6' containerClassName="sysconf-inputAddon" id="covSumPeriod" name="covSumPeriod" addonMsg="common.lbl.months"></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Update Coverage Summary History Period end */}

        <props.HR></props.HR>

        {/* Update Enrollment Termination Date Limit start */}
        <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label" htmlFor="enrollTemLimit">
                    <GlobalizedText message="sysconf.enrollment.termination.date.limit" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{ enrollTemLimit: config.systemConfig['enrollmentLimitPeriod'] }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(enrollTemLimitSchema, values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateEnrollmentLimit',
                    params: {
                        "enrollmentLimitPeriod": values.enrollTemLimit
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "sysconf.enrollment.limit.update.success");
                    },
                    fail: handelFailed,
                    error: handelError
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='6' containerClassName="sysconf-inputAddon" id="enrollTemLimit" name="enrollTemLimit" addonMsg="common.lbl.months"></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Update Enrollment Termination Date Limit end */}

        <props.HR></props.HR>

        {/* Updadte Export User List start */}
        <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label exportSubject" htmlFor="userList">
                    <GlobalizedText message="common.msg.export.userlist" />
                </props.Label>
            </props.Col>
        </props.Row>
        <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label" htmlFor="exportFrequency">
                    <GlobalizedText message="common.msg.export.frequency" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{
                exportFrequency: config.systemConfig['exportFrequency'] !== "" ? config.systemConfig['exportFrequency'] : config.defaultExportFrequency,
                exportStartDay: config.systemConfig['exportStartDay'] !== "" ? config.systemConfig['exportStartDay'] : config.defaultExportStartDay,
                exportStartTime: config.systemConfig['exportStartTime'] !== "" ? config.systemConfig['exportStartTime'] : config.defaultExportStartTime
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateExportUserListFrequency',
                    params: {
                        "exportFrequency": values.exportFrequency,
                        "exportStartDay": values.exportStartDay,
                        "exportStartTime": values.exportStartTime,
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "common.msg.UserListFrequencyUpdateSuccess");
                    }, fail: res => {
                        //props.showMessage*()
                    }
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.Row>
                                <props.Col sm="12" md="12" xs="12">
                                    <props.SelectControl className="exportSelectFrequency" name="exportFrequency" defaultOption="On Demand" sort={false} label="" id="exportFrequency" options={config.exportFrequencyList} />
                                </props.Col>
                            </props.Row>
                            <props.Row>
                                <props.Col sm="12" md="12" xs="12">
                                    {formProps.values.exportFrequency === 'W' && <>
                                        <props.Label className="gwp-label" htmlFor="exportStartDay">
                                            <GlobalizedText message="common.msg.export.start.time" />
                                        </props.Label>
                                    </>}
                                </props.Col>
                            </props.Row>
                            <props.Row>
                                {formProps.values.exportFrequency === 'D' && <>
                                    <props.Col sm="4" md="4" xs="12" className="exportTimeTitle">
                                        <props.Label className="gwp-label" htmlFor="exportStartTime">
                                            <GlobalizedText message="common.msg.export.start.day" />
                                        </props.Label>
                                    </props.Col>
                                    <props.Col sm="8" md="8" xs="12">
                                        <props.SelectControl className="exportSelect" name="exportStartTime" label="" required={false} sort={false} options={config.exportTimeList}
                                            condition={formProps.values.exportFrequency === 'W' || formProps.values.exportFrequency === 'D'} />
                                    </props.Col>
                                </>}
                                {formProps.values.exportFrequency === 'W' && <>
                                    <props.Col sm="12" md="12" xs="12">
                                        <props.SelectControl className="exportSelect" name="exportStartDay" label="" required={false} sort={false}
                                            options={config.exportDayList} defaultOption="Sunday" condition={formProps.values.exportFrequency === 'W'} />
                                        <props.SelectControl className="exportSelect" name="exportStartTime" label="" required={false} sort={false} options={config.exportTimeList}
                                            condition={formProps.values.exportFrequency === 'W' || formProps.values.exportFrequency === 'D'} />
                                    </props.Col>
                                </>}
                            </props.Row>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn exportButton"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>

        {/* Updadte Export User List Duration start */}
        <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label" htmlFor="userListDuration">
                    <GlobalizedText message="common.msg.export.userlist.duration" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{ userListDuration: config.systemConfig['userListDuration'] }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(userListDurationSchema, values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateExportUserListDuration',
                    params: {
                        "userListFileDuration": values.userListDuration,
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "common.msg.ExportUserListDurationUpdateSuccess");
                    },
                    fail: handelFailed,
                    error: handelError
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl containerClassName="sysconf-inputAddon" maxLength="6" id="userListDuration" name="userListDuration" addonMsg="common.lbl.months"></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Updadte Export User List Duration end */}

        <props.HR></props.HR>

        {/* Update Payroll schedule period start */}
        <props.Row condition={isDisplayPayrollSchedule}>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label" htmlFor="payrollSchPeriod">
                    <GlobalizedText message="sysconf.payroll.schedule.period" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{ payrollSchPeriod: config.systemConfig['payrollSchedulePeriod'] }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(payrollSchPeriodSchema, values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updatePayrollSchedulePeriod',
                    params: {
                        "payrollSchedulePeriod": values.payrollSchPeriod,
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "sysconf.payroll.schedule.period.success");
                    },
                    fail: handelFailed,
                    error: handelError
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row condition={isDisplayPayrollSchedule}>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='6' containerClassName="sysconf-inputAddon" id="payrollSchPeriod" name="payrollSchPeriod" addonMsg="common.lbl.months"></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="4" md="4" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Update Payroll schedule period start */}
        <props.Row condition={isDisplayPayrollSchedule}>
            <props.HR></props.HR>
        </props.Row>
        {/** Email Configuration start*/}

        <props.Row>
            <props.Col sm="4" md="4" xs="6">
                <props.Label className="gwp-label addLabel">
                    <GlobalizedText message="common.lbl.mailConf" />
                </props.Label>
            </props.Col>
            <props.Col sm="8" md="8" xs="6">
                <props.Button onClick={() => openEmailUpdateDialog()}><GlobalizedText message="common.lbl.update" /></props.Button>
            </props.Col>
        </props.Row>

        <Formik
            enableReinitialize={true}
            initialValues={
                {
                    emailEnabled: config.systemConfig['mailEnabled'],
                    emailProtocol: config.systemConfig['mailTransportProtocol'],
                    emailAuth: config.systemConfig['mailSmtpAuth'],
                    emailStart: config.systemConfig['mailSmtpStarttlsEnable'],
                    emailHost: config.systemConfig['mailSmtpHost'],
                    emailTrust: config.systemConfig['mailSmtpSslTrust'],
                    emailPort: config.systemConfig['mailSmtpPort'],
                    emailFrom: config.systemConfig['mailFrom'],
                    emailPwd: config.systemConfig['mailPassword'],
                    emailUser: config.systemConfig['mailUser'],
                    emailSub: config.systemConfig['mailSubject'],
                    emailBody: config.systemConfig['mailBody'],
                    emailSocketPort: config.systemConfig['mailSmtpSocketFactoryPort'],
                    emailAlias: config.systemConfig['mailAlias'],
                    messageCenterMailEnabled: config.systemConfig['messageCenterMailEnabled'],
                    messageCenterMailSubject: config.systemConfig['messageCenterMailSubject'],
                    messageCenterMailBody: config.systemConfig['messageCenterMailBody'],
                    messageCenterMailSender: config.systemConfig['messageCenterMailSender']
                }

            }
            validateOnBlur={false}
            validateOnChange={true}
            validate={(values) => {
                return validate(emailUpdateSchema, values, messageService4Email.validateProps);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateMailConfiguration',
                    data: {
                        mailEnabled: values.emailEnabled,
                        mailTransportProtocol: values.emailProtocol,
                        mailSmtpAuth: values.emailAuth,
                        mailSmtpHost: values.emailHost,
                        mailSmtpPort: values.emailPort,
                        mailSmtpStarttlsEnable: values.emailStart,
                        mailSmtpSslTrust: values.emailTrust,
                        mailSmtpSocketFactoryPort: values.emailSocketPort,
                        mailUser: values.emailUser,
                        mailPassword: values.emailPwd,
                        mailSubject: values.emailSub,
                        mailBody: values.emailBody,
                        mailFrom: values.emailFrom,
                        mailAlias: values.emailAlias,
                        messageCenterMailEnabled: values.messageCenterMailEnabled,
                        messageCenterMailSubject: values.messageCenterMailSubject,
                        messageCenterMailBody: values.messageCenterMailBody,
                        messageCenterMailSender: values.messageCenterMailSender
                    },
                    method: 'post',
                    success: res => {
                        setIsUpdated(true);
                        props.showMessage("success", "mail.configuration.update.success");
                        props.closeModal("#email_conf")
                    },
                    fail: handelFailed,
                    error: handelError
                });
            }
            }

        >
            {formProps =>
                <Form>
                    <props.Modal title="common.lbl.mailConf" id="email_conf" footer={() => {
                        return <>
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.lbl.save" /></props.Button>
                            <props.Button data-dismiss="modal" onClick={(e: any) => { resetEmailForm(props, formProps) }}><GlobalizedText message="common.lbl.close" /></props.Button>
                        </>
                    }} messageService={messageService4Email.messageService}>

                        <props.Row>
                            <props.Col sm="12">
                                <props.TextControl id="emailProtocol" name="emailProtocol" label="common.lbl.transportprotocol" required={true}></props.TextControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.LabelAddOnControl id="emailAuth" checked={formProps.values.emailAuth === "true"} name='emailAuth' labelMsg='common.lbl.smtpauth' onChange={(e: any) => {
                                    let ele = document.getElementById("emailAuth");
                                    if (ele != null && ele.getAttribute("value") === "true") {
                                        formProps.setFieldValue("emailAuth", "false")
                                    } else {
                                        formProps.setFieldValue("emailAuth", "true")
                                    }
                                }}></props.LabelAddOnControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.LabelAddOnControl id="emailStart" checked={formProps.values.emailStart === "true"} name='emailStart' labelMsg='common.lbl.smtpStarttlsEnable' onChange={(e: any) => {
                                    let ele = document.getElementById("emailStart");
                                    if (ele != null && ele.getAttribute("value") === "true") {
                                        formProps.setFieldValue("emailStart", "false")
                                    } else {
                                        formProps.setFieldValue("emailStart", "true")
                                    }
                                }}></props.LabelAddOnControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.TextControl id="emailHost" name="emailHost" label="common.lbl.smtphost" required={true}></props.TextControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.TextControl id="emailTrust" name="emailTrust" label="common.lbl.smtpssltrust" required={true}></props.TextControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.TextControl id="emailPort" name="emailPort" label="common.lbl.smtpport" required={true}></props.TextControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.TextControl id="emailSocketPort" name="emailSocketPort" label="common.lbl.smtpsocketfactoryport" required={true}></props.TextControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.TextControl id="emailUser" name="emailUser" label="common.lbl.mailuser" required={true}></props.TextControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.PasswordControl id="emailPwd" name="emailPwd" label="common.lbl.mailpassword" required={true}></props.PasswordControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.TextControl id="emailFrom" name="emailFrom" label="common.lbl.mailfrom" required={true}></props.TextControl>
                            </props.Col>
                            <props.Col sm="6">
                                <props.TextControl id="emailAlias" name="emailAlias" label="common.lbl.mailalias" required={true}></props.TextControl>
                            </props.Col>
                            <props.Col sm="12">
                                <ul id="sctab" className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className={tab === 'forgetUserId' ? 'active' : ''}><a href="#forgetUserIdTab" onClick={() => clickTab('forgetUserId', formProps)} aria-controls="forgetUserIdTab" role="tab" data-toggle="tab"><GlobalizedText message="mailconfig.lbl.forgotUserID" /></a></li>
                                    <li role="presentation" className={tab === 'messageCenter' ? 'active' : ''}><a href="#messageCenterTab" onClick={() => clickTab('messageCenter', formProps)} aria-controls="messageCenterTab" role="tab" data-toggle="tab"><GlobalizedText message="homepage.userInquiry" /></a></li>
                                </ul>
                                <div id="scTabContent" className="tab-content">
                                    <div role="tabpanel" className={`tab-pane fade in ${tab === 'forgetUserId' ? 'active' : ''}`} id="forgetUserIdTab">
                                        <div id="forgetUserIdDiv">
                                            {/**Forgot UserID tab**/}
                                            <props.LabelAddOnControl id="emailEnable" checked={formProps.values.emailEnabled === "true"} name='emailEnabled' labelMsg='common.lbl.mailEnabled' onChange={(e: any) => {
                                                let ele = document.getElementById("emailEnable");
                                                if (ele != null && ele.getAttribute("value") === "true") {
                                                    formProps.setFieldValue("emailEnabled", "false")
                                                } else {
                                                    formProps.setFieldValue("emailEnabled", "true")
                                                }
                                            }}></props.LabelAddOnControl>
                                            <props.TextControl style={{ maxWidth: '100%' }} id="emailSub" name="emailSub" label="common.lbl.mailsubject" required={true}></props.TextControl>
                                            <props.TextareaControl style={{ maxWidth: '100%' }} id="emailBody" name="emailBody" label="common.lbl.mailbody" required={true}></props.TextareaControl>
                                        </div>
                                    </div>
                                    <div role="tabpanel" className={`tab-pane fade in ${tab === 'messageCenter' ? 'active' : ''}`} id="messageCenterTab">
                                        {/**Message Center tab**/}
                                        <props.LabelAddOnControl id="messageCenterMailEnabled" checked={formProps.values.messageCenterMailEnabled === "true"} name='messageCenterMailEnabled' labelMsg='common.lbl.mailEnabled' onChange={(e: any) => {
                                            let ele = document.getElementById("messageCenterMailEnabled");
                                            if (ele != null && ele.getAttribute("value") === "true") {
                                                formProps.setFieldValue("messageCenterMailEnabled", "false")
                                            } else {
                                                formProps.setFieldValue("messageCenterMailEnabled", "true")
                                            }
                                        }}></props.LabelAddOnControl>

                                        <props.TextControl style={{ maxWidth: '100%' }} id="messageCenterMailSubject" name="messageCenterMailSubject" label="common.lbl.mailsubject" required={true}></props.TextControl>
                                        <props.TextareaControl style={{ maxWidth: '100%' }} id="messageCenterMailBody" name="messageCenterMailBody" label="common.lbl.mailbody" required={true}></props.TextareaControl>
                                    </div>
                                </div>
                            </props.Col>
                        </props.Row>
                    </props.Modal>
                </Form>
            }
        </Formik>
        {/** Email Configuration end */}


        <props.HR></props.HR>

        {/* Update Number of Years Account Terminated start */}
        <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label" htmlFor="memYearTem" >
                    <GlobalizedText message="common.lbl.meber.year.termination" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{ memYearTem: config.systemConfig['numberOfYear'] }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(memYearTemSchema, values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateMemberNumberOfYear',
                    params: {
                        "numberOfYear": values.memYearTem,
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "common.msg.memberyearupdateSuccess");
                    }, fail: res => {
                        //props.showMessage*()
                    }
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='6' containerClassName="sysconf-inputAddon" id="memYearTem" name="memYearTem" addonMsg="common.lbl.years"></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Update Number of Years Account Terminated end */}

        <props.HR></props.HR>

        {/* Update payment history period start */}
        <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label" htmlFor="billPayInqPeriod" >
                    <GlobalizedText message="sysconf.billpay.inquiry.period" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{ billPayInqPeriod: config.systemConfig['paymentHistoryPeriod'] }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(billPayInqPeriodSchema, values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateBillpayPeriod',
                    params: {
                        "paymentHistoryPeriod": values.billPayInqPeriod,
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "sysconf.billpay.inquiry.period.update.success");
                    }, fail: res => {
                        //props.showMessage*()
                    }
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='6' containerClassName="sysconf-inputAddon" id="billPayInqPeriod" name="billPayInqPeriod" addonMsg="common.lbl.months"></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Update payment history period emd */}

        <props.HR></props.HR>

        {/* Update policy note period start*/}
        <props.Row condition={isDisplayPolicyNotes}>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label" htmlFor="polNotesPeriod">
                    <GlobalizedText message="sysconf.policy.notes.period" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{ polNotesPeriod: config.systemConfig['policyNotesPeriod'] }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(polNotesPeriodSchema, values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updatePlicyNotesPeriod',
                    params: {
                        "policyNotesPeriod": values.polNotesPeriod,
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "sysconf.policy.notes.period.success");
                    }, fail: res => {
                        //props.showMessage*()
                    }
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row condition={isDisplayPolicyNotes}>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='6' containerClassName="sysconf-inputAddon" id="polNotesPeriod" name="polNotesPeriod" addonMsg="common.lbl.months"></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Update policy note  period end*/}
        <props.Row condition={isDisplayPolicyNotes}>
            <props.HR></props.HR>
        </props.Row>
        {/* Synchronize CertificateNumber/IdentificationNumber start */}
        <Formik
            initialValues={{ syncid: '' }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateTransIds',
                    method: 'post',
                    success: res => {
                        props.showMessage("success", "sysconf.trans.ids.update.success");
                    }, fail: res => {
                        //props.showMessage*()
                    }
                });

            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.Label className="gwp-label addLabel">
                                <GlobalizedText message="common.lbl.enrollment.transaction.ids" />
                            </props.Label>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.lbl.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Synchronize CertificateNumber/IdentificationNumber start */}

        <props.HR></props.HR>

        {/* Synchronize Group/Account Description start */}
        <Formik
            initialValues={{ syncid: '' }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateNameDescription',
                    method: 'post',
                    success: res => {
                        props.showMessage("success", "sysconf.name.description.update.success");
                    }, fail: res => {
                        //props.showMessage*()
                    }
                });

            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.Label className="gwp-label addLabel">
                                <GlobalizedText message="common.lbl.group.account.description" />
                            </props.Label>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.lbl.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Synchronize Group/Account Description start */}

        <props.HR></props.HR>

        {/* Updadte User Inquiry Limit Re-assigned Display start updateUserInquiryPeriod userInqPeriodSchema*/}
        <props.Row>
            <props.Col sm="12" md="12" xs="12">
                <props.Label className="gwp-label" htmlFor="userInqPeriod">
                    <GlobalizedText message="common.msg.UIDisplay" />
                </props.Label>
            </props.Col>
        </props.Row>
        <Formik
            initialValues={{ userInqPeriod: config.systemConfig['userInquiryPeriod'] }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => {
                return validate(userInqPeriodSchema, values, props);
            }}
            onSubmit={(values) => {
                ajax({
                    url: '/api/systemConfiguration/updateUserInquiryPeriod',
                    params: {
                        "userInquiryPeriod": values.userInqPeriod,
                    },
                    method: 'put',
                    success: res => {
                        props.showMessage("success", "common.msg.UIPeriodUpdateSuccess");
                    }, fail: res => {
                        //props.showMessage*()
                    }
                });
            }
            }
        >
            {formProps =>
                <Form className="form-inline">
                    <props.Row>
                        <props.Col sm="4" md="4" xs="6">
                            <props.InputAddonControl maxLength='6' containerClassName="sysconf-inputAddon" id="userInqPeriod" name="userInqPeriod" addonMsg="common.lbl.days"></props.InputAddonControl>
                        </props.Col>
                        <props.Col sm="8" md="8" xs="6">
                            <props.Button type="submit" className="btn btn-primary gwp-btn"><GlobalizedText message="common.button.submit" /></props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
            }
        </Formik>
        {/* Updadte User Inquiry Limit Re-assigned Display start*/}
        <props.HR></props.HR>

        {/* Updadte Data Request Limit start */}
		<Section {...props} name="limit" messageId="common.msg.request.limit"/>

		<Heading {...props} name="requestLimitAccount" messageId="common.msg.request.limit.account"/>
 		<AnnotatableForm {...props} name="requestLimitAccount" parameterName="request.limit.account" messageId="common.lbl.max" schema={naturalNumberSchema} />
        <props.HR></props.HR>

		<Heading {...props} name="requestLimitMember" messageId="common.msg.request.limit.member"/>
 		<AnnotatableForm {...props} name="requestLimitMember" parameterName="request.limit.member" messageId="common.lbl.max" schema={naturalNumberSchema} />
        <props.HR></props.HR>

		<Heading {...props} name="requestLimitPerson" messageId="common.msg.request.limit.person"/>
		<AnnotatableForm {...props} name="requestLimitPerson" parameterName="request.limit.person" messageId="common.lbl.max" schema={naturalNumberSchema} />
		<StandardForm {...props} name="requestLimitPersonTimeout" parameterName="request.limit.person.timeout" messageId="common.lbl.timeout" schema={naturalNumberSchema} />
    </>
});